import React, { useState, useEffect } from 'react';
import '../CartTotal/CartTotal.scss';
import { useSelector, useDispatch } from 'react-redux';
import { clearCart } from '../../redux/cartReducer';
import { makeRequest } from '../../makeRequest';
import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios';

const SHIPPING_RATE = 0;

const CartTotal = () => {
  const dispatch = useDispatch();
  const products = useSelector(state => state.cart.products);
  const [coupon, setCoupon] = useState('');
  const [couponApplied, setCouponApplied] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [total, setTotal] = useState(0);

  const subtotal = products.reduce((total, product) => {
    return total + (product.price * product.quantity);
  }, 0);

  const shipping = subtotal * SHIPPING_RATE;

  useEffect(() => {
    setTotal(subtotal + discountAmount + shipping);
  }, [subtotal, discountAmount, shipping]);

  const handleClearCart = () => {
    dispatch(clearCart());
  };
  
  const stripePromise = loadStripe(
    'pk_live_51MrR92HPnT4mYt5h97Bsml3cZXXg0WXW73PND9h71HSQXxIQc9d97uk0uNsaKMbRFm394oxK7h71GI78NcaQDQ7600aLLv366S'
  );

  const handlePayment = async () => {
    try {
      const stripe = await stripePromise;
      const res = await makeRequest.post("/orders", {
        products,couponApplied
      });
      await stripe.redirectToCheckout({
        sessionId: res.data.stripeSession.id,
      });

    } catch (error) {
      console.log(error);
    }

  };
  const handleCouponChange = (event) => {
    setCoupon(event.target.value);
  };

  const handleCoupon = async () => {
    try {
      const couponCode = coupon; // Replace with the actual coupon code
  
      const response = await axios.post(
        'https://gallopguard.com/validate-coupon',
        {
          couponCode
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
  
      const { success, discount, type, error } = response.data;
  
      if (success) {
        if(type === 'percent'){
          const amount = (subtotal * (discount/100.00));
          setDiscountAmount(amount * -1);

        }else{
          const amount = discount;
          setDiscountAmount(amount * -1);
        }
        setCouponApplied(coupon);
        setCoupon('');
      } else {
        console.log(`Coupon validation failed. Error: ${error}`);
        alert(error);
        setCoupon('');
      }
    } catch (error) {
      console.log(`Error occurred while calling the API: ${error.message}`);
      alert("Server error: contact support.")
    }
  };

  return (
    <div className="cart-total">
      <h3 className="cart-total-heading">Cart Total</h3>
      <div className="cart-total-subtotal"><span className="cart-total-category">Subtotal:</span> ${subtotal.toFixed(2)}</div>
      <div className="cart-total-tax"><span className="cart-total-category">Discount:</span> <span style={{color:'red'}}>${discountAmount.toFixed(2)} </span></div>
      <div className="cart-total-shipping"><span className="cart-total-category">Shipping and Handling:</span> ${shipping.toFixed(2)}</div>
      <div className="cart-total-divider"></div>
      <div className="cart-total-total"><span className="cart-total-category">Total:</span> ${total.toFixed(2)}</div>
      <div className="cart-total-tax"><p style={{color:'gray',fontSize:'1rem'}}>*Tax calculated on checkout</p></div>
      <div className="cart-total-space"></div>
      <div className="cart-coupon-container">
        <input
          type="text"
          className="cart-coupon-input"
          placeholder="Enter coupon code"
          value={coupon}
          onChange={handleCouponChange}
        />
        <button className="cart-coupon-apply" onClick={handleCoupon}>Apply</button>
      </div>

      <button className="cart-total-button" onClick={handlePayment}>Checkout</button>
      <span className="cart-clear-container">
        <button className="cart-clear-button" onClick={handleClearCart}>Clear Cart</button>
      </span>
    </div>
  );
};

export default CartTotal;