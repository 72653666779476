import '../Item/TestimonialItem.scss'

import React from 'react'
import PropTypes from 'prop-types';

const TestimonailItem = ({ title, text, imageUrl }) => {
  return (
    <div className="testimonial-container">
      <div className="testimonial-card">
        <h1 className="title">{title}</h1>
        <p className="text">{text}</p>
        <img src={imageUrl} className="image" alt='testimonial-person'/>
      </div>
    </div>
    
  );
}

TestimonailItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  imageUrl: PropTypes.string,
};

TestimonailItem.defaultProps = {
  title: 'Default Name',
  text: 'Testimonial text, this is where I get to say a few lines about how great the product is and why you should use it.',
  imageUrl: 'https://wixplosives.github.io/codux-assets-storage/add-panel/image-placeholder.jpg',
};

export default TestimonailItem;
