import React from 'react';
import "./Navbar.scss";
import { useSelector } from 'react-redux';

const Navbar = () => {
  const handleClick = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      localStorage.setItem('navId', id);
      window.location.href = '/';
    }
  };

  const cartQuantity = useSelector(state => state.cart.products.reduce((total, product) => total + product.quantity, 0));

  
  return (
    <nav className="top-navbar">
      <ul className="nav-links">
        <li><a href="/#">Home</a></li>
        <li><a href="/#products" onClick={(event) => handleClick(event, 'products')}>Products</a></li>
        <li><a href="/#contactUs" onClick={(event) => handleClick(event, 'contactUs')}>Contact Us</a></li>
        <li><a href="/cart">Cart (<span style={{color:'red'}}>{cartQuantity}</span>)</a></li>
      </ul>
    </nav>
  )
}

export default Navbar