import React from 'react'
import '../ReturnPolicy/ReturnPolicy.scss'
const ReturnPolicy = () => {
    return (
        <div className="return-policy">
            <div className="c3 doc-content">
                <h1>Return Policy</h1>
                <p className="c1"><span className="c0">RETURNS</span></p>
                <p className="c1 c2"><span className="c0"></span></p>
                <p className="c1"><span className="c0">----</span></p>
                <p className="c1 c2"><span className="c0"></span></p>
                <p className="c1"><span className="c0">We have a 30-day return policy, which means you have 30 days after receiving your
                    item to request a return.</span></p>
                <p className="c1 c2"><span className="c0"></span></p>
                <p className="c1"><span className="c0">To be eligible for a return, your item must be in the same condition that you
                    received it, unworn or unused, with tags, and in its original packaging. You&#39;ll also need the receipt or
                    proof of purchase.</span></p>
                <p className="c1 c2"><span className="c0"></span></p>
                <p className="c1"><span className="c0">To start a return, you can contact us at sales@gallopguard.com. Please note that
                    returns will need to be sent to the following address:</span></p>
                <p className="c1"><span className="c0">7923 Gloria Ave., Van Nuys, CA 91406</span></p>
                <p className="c1 c2"><span className="c0"></span></p>
                <p className="c1"><span className="c0">If your return is accepted, we&rsquo;ll send you a return shipping label, as well as
                    instructions on how and where to send your package. Items sent back to us without first requesting a return
                    will not be accepted. Please note that if your country of residence is not United States, shipping your
                    goods may take longer than expected.</span></p>
                <p className="c1 c2"><span className="c0"></span></p>
                <p className="c1"><span className="c0">You can always contact us for any return questions at eduard@gallopguard.com.</span>
                </p>
                <p className="c1 c2"><span className="c0"></span></p>
                <p className="c1"><span className="c0">Damages and Issues</span></p>
                <p className="c1 c2"><span className="c0"></span></p>
                <p className="c1"><span className="c0">Please inspect your order upon receipt and contact us immediately if the item is
                    defective, damaged, or if you receive the wrong item, so that we may evaluate the issue and make it
                    right.</span></p>
                <p className="c1"><span className="c0">Certain types of items cannot be returned, like perishable goods (such as food,
                    flowers, or plants), custom products (such as special orders or personalized items), and personal care goods
                    (such as beauty products). We also do not accept returns for hazardous materials, flammable liquids, or
                    gases. Please get in touch if you have questions or concerns about your specific item.</span></p>
                <p className="c1 c2"><span className="c0"></span></p>
                <p className="c1"><span className="c0">Unfortunately, we cannot accept returns on sale items or gift cards.</span></p>
                <p className="c1 c2"><span className="c0"></span></p>
                <p className="c1"><span className="c0">Exchanges</span></p>
                <p className="c1 c2"><span className="c0"></span></p>
                <p className="c1"><span className="c0">The fastest way to ensure you get what you want is to return the item you have, and
                    once the return is accepted, make a separate purchase for the new item.</span></p>
                <p className="c1 c2"><span className="c0"></span></p>
                <p className="c1"><span className="c0">European Union 14 day cooling off period</span></p>
                <p className="c1"><span className="c0">Notwithstanding the above, if merchandise is being shipped into the European Union,
                    you have the right to cancel or return your order within 14 days for any reason and without justification.
                    As above, your item must be in the same condition that you received it, unworn or unused, with tags, and in
                    its original packaging. You&rsquo;ll also need the receipt or proof of purchase.</span></p>
                <p className="c1 c2"><span className="c0"></span></p>
                <p className="c1"><span className="c0">Refunds</span></p>
                <p className="c1 c2"><span className="c0"></span></p>
                <p className="c1"><span className="c0">We will notify you once we&rsquo;ve received and inspected your return to let you
                    know if the refund was approved or not. If approved, you&rsquo;ll be automatically refunded on your original
                    payment method within 10 business days. Please remember it can take some time for your bank or credit card
                    company to process and post the refund too.</span></p>
                <p className="c1 c2"><span className="c0"></span></p>
                <p className="c1"><span className="c0">If more than 15 business days have passed since we&rsquo;ve approved your return,
                    please contact us at sales@gallopguard.com.</span></p>
                <p className="c1 c2"><span className="c0"></span></p>
            </div>
        </div>
    )
}

export default ReturnPolicy