import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    products: []
  },
  reducers: {
    addToCart: (state, action) => {
      const item = state.products.find(item => item.id === action.payload.id)

      if (item) {
        item.quantity += 1;
      } else {
        state.products.push(action.payload);
      }
    },
    decrement: (state, action) => {
      const item = state.products.find(item => item.id === action.payload.id)

      if (item && item.quantity > 1) {
        item.quantity -= 1;
      } else {
        state.products = state.products.filter(item => item.id !== action.payload.id);
      }
    },
    increment: (state, action) => {
      const item = state.products.find(item => item.id === action.payload.id)

      if (item) {
        item.quantity += 1;
      }
    },
    clearCart: (state) => {
        state.products = [];
    },

    removeItem: (state, action) => {
      state.products = state.products.filter(item => item.id !== action.payload.id);
    },
  },
})

export const { addToCart, decrement, increment, clearCart, removeItem } = cartSlice.actions;

export default cartSlice.reducer;
