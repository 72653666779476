import React from 'react'
import '../Banner/Banner.scss'
const Banner = () => {
  return (
    <div className='banner-container'>
        <p className="banner-text">
            Grand Opening Promotion! FREE SHIPPING on all orders!
        </p>
    </div>
  )
}

export default Banner