
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import Cart from "./pages/Cart/Cart";
import PostCheckout from "./pages/PostCheckout/PostCheckout";
import TOS from "./pages/TOS/TOS";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ReturnPolicy from "./pages/ReturnPolicy/ReturnPolicy";
import Banner from "./components/Banner/Banner";



const Layout = () => {
  return(
    <div className="app">
      <Banner />
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path:"/",
        element:<Home/>
      },
      {
        path:"/cart",
        element: <Cart />
      },
      {
        path:"/checkout",
        element: <PostCheckout />
      },
      {
        path:"/tos",
        element: <TOS />
      },
      {
        path:"/privacy-policy",
        element: <PrivacyPolicy />
      },
      {
        path:"/return-policy",
        element: <ReturnPolicy />
      },
    ]
  },
]);


function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
