import React from 'react'
import '../PrivacyPolicy/PrivacyPolicy.scss'

const PrivacyPolicy = () => {
    return (
        <div className='privacy-policy'>
            <div className="c3 doc-content ">

                <h1>Privacy Policy</h1>
                <p className="c2"><span className="c0">GallopGuard Co. Privacy Policy</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">This Privacy Policy describes how your personal information is collected, used, and
                    shared when you visit or make a purchase from https://gallopguard.com (the &ldquo;Site&rdquo;).</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">PERSONAL INFORMATION WE COLLECT</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">When you visit the Site, we automatically collect certain information about your
                    device, including information about your web browser, IP address, time zone, and some of the cookies that
                    are installed on your device. Additionally, as you browse the Site, we collect information about the
                    individual web pages or products that you view, what websites or search terms referred you to the Site, and
                    information about how you interact with the Site. We refer to this automatically-collected information as
                    &ldquo;Device Information.&rdquo;</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">We collect Device Information using the following technologies:</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">&nbsp; &nbsp; - &ldquo;Cookies&rdquo; are data files that are placed on your device
                    or computer and often include an anonymous unique identifier. For more information about cookies, and how to
                    disable cookies, visit http://www.allaboutcookies.org.</span></p>
                <p className="c2"><span className="c0">&nbsp; &nbsp; - &ldquo;Log files&rdquo; track actions occurring on the Site, and
                    collect data including your IP address, browser type, Internet service provider, referring/exit pages, and
                    date/time stamps.</span></p>
                <p className="c2"><span className="c0">&nbsp; &nbsp; - &ldquo;Web beacons,&rdquo; &ldquo;tags,&rdquo; and
                    &ldquo;pixels&rdquo; are electronic files used to record information about how you browse the Site.</span>
                </p>
                <p className="c2"><span className="c0">Additionally when you make a purchase or attempt to make a purchase through the Site,
                    we collect certain information from you, including your name, billing address, shipping address, payment
                    information including credit card numbers, email address, and phone number. &nbsp;We refer to this
                    information as &ldquo;Order Information.&rdquo;</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">When we talk about &ldquo;Personal Information&rdquo; in this Privacy Policy, we are
                    talking both about Device Information and Order Information.</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">HOW DO WE USE YOUR PERSONAL INFORMATION?</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">We use the Order Information that we collect generally to fulfill any orders placed
                    through the Site (including processing your payment information, arranging for shipping, and providing you
                    with invoices and/or order confirmations). &nbsp;Additionally, we use this Order Information to:</span></p>
                <p className="c2"><span className="c0">Communicate with you;</span></p>
                <p className="c2"><span className="c0">Screen our orders for potential risk or fraud; and</span></p>
                <p className="c2"><span className="c0">When in line with the preferences you have shared with us, provide you with
                    information or advertising relating to our products or services.</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">We use the Device Information that we collect to help us screen for potential risk
                    and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for
                    example, by generating analytics about how our customers browse and interact with the Site, and to assess
                    the success of our marketing and advertising campaigns).</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">SHARING YOUR PERSONAL INFORMATION</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">We share your Personal Information with third parties to help us use your Personal
                    Information, as described above. &nbsp;For example, we use Stripe to power our online store. We also use
                    Google Analytics to help us understand how our customers use the Site--you can read more about how Google
                    uses your Personal Information here: &nbsp;https://www.google.com/intl/en/policies/privacy/. &nbsp;You can
                    also opt-out of Google Analytics here: &nbsp;https://tools.google.com/dlpage/gaoptout.</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">Finally, we may also share your Personal Information to comply with applicable laws
                    and regulations, to respond to a subpoena, search warrant or other lawful request for information we
                    receive, or to otherwise protect our rights.</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">DO NOT TRACK</span></p>
                <p className="c2"><span className="c0">Please note that we do not alter our Site&rsquo;s data collection and use practices
                    when we see a Do Not Track signal from your browser.</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">DATA RETENTION</span></p>
                <p className="c2"><span className="c0">When you place an order through the Site, we will maintain your Order Information for
                    our records unless and until you ask us to delete this information.</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">CHANGES</span></p>
                <p className="c2"><span className="c0">We may update this privacy policy from time to time in order to reflect, for example,
                    changes to our practices or for other operational, legal or regulatory reasons.</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">CONTACT US</span></p>
                <p className="c2"><span className="c0">For more information about our privacy practices, if you have questions, or if you
                    would like to make a complaint, please contact us by e-mail at eduard@gallopguard.com or by mail using the
                    details provided below:</span></p>
                <p className="c1"><span className="c0"></span></p>
                <p className="c2"><span className="c0">&nbsp; 1603 Capitol Ave. STE 413A, #3372, Cheyenne, WY, 82001, United States</span>
                </p>
                <p className="c1"><span className="c0"></span></p>
            </div>
        </div>

    )
}

export default PrivacyPolicy