import React from 'react'
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearCart } from '../../redux/cartReducer';
import '../CheckoutResponse/CheckoutResponse.scss';

const CheckoutResponse = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');
    const orderId = searchParams.get('order_id');
    const dispatch = useDispatch();

    const handleContinue = () => {
        window.location.href = '/';
    };

    // const orderConfirmationEmail = async () => {
    //     let details = {
    //         orderNumber: orderId,
    //       };
    //       let response = await fetch("https://gallopguard.com:3000/order-confirmation", {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json;charset=utf-8",
    //         },
    //         body: JSON.stringify(details),
    //       });
    //       console.log(response);
    // }  
    
    if (success === 'true') {
        // orderConfirmationEmail();
        dispatch(clearCart());
    }
    

    return (
        <div className="checkout-response">
            {success === 'true' ? (
                <div className='checkout-response-success'>
                    <h1>Thank you for your purchase!</h1>
                    <h2><span>Order #: </span>{orderId}</h2>
                    <p>Your order has been successfully processed and will be shipped soon.</p>
                    <p>We'll email you an order confirmation with details.</p>
                    <button onClick={handleContinue}>Continue Shopping</button>
                </div>

            ) : (
                <div className='checkout-response-failed'>
                    <h1>Oops! Looks Like Something Went Wrong</h1>
                    <h2>Unfortunately, your order could not be processed.</h2>
                    <p>Please try again.</p>
                    <p>If this issue persists, please contact our support team at <a href='mailto:eduard@gallopguard.com'>eduard@gallopguard.com</a></p>
                    <button onClick={handleContinue}>Return To Site</button>
                </div>
            )}
        </div>
    )
}

export default CheckoutResponse