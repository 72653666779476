import React from 'react';
import './FAQ.scss';
import Question  from '../Question/Question';

const FAQ = ({ questions }) => {
  return (
    <div className="faq">
      {questions.map((q, i) => (
        <Question key={i} question={q.question} answer={q.answer} />
      ))}
    </div>
  );
};

export default FAQ;
