import React, { useState, useEffect } from 'react'
import Hero from '../../components/Hero/Hero'
import Diffrenece from '../../components/Diffrenece/Diffrenece'
import ProductCard from '../../components/ProductCard/ProductCard'
import '../Home/Home.scss'
import { Parallax } from 'react-parallax'
import Horse from '../img/horses_g.jpg'
import Carousel from 'react-elastic-carousel'
import TestimonialItem from '../../components/Item/TestimonialItem'
import FAQ from '../../components/FAQ/FAQ'
import ContactUs from '../../components/ContactUs/ContactUs'
import axios from 'axios';
import TJackie from '../img/t-jackie.png'
import TEnoc from '../img/t-enoc.png'
import TDefault from '../img/t-default.png'


const Home = () => {
  const [itemsToShow, setItemsToShow] = useState(2);
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1440) {
        setItemsToShow(3);
      }else if (window.innerWidth > 710) {
        setItemsToShow(2);
      } else {
        setItemsToShow(1);
      }
    };

    handleResize(); // initial call to set the initial state

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async ()=> {
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"/products?populate=*",{
          headers:{
            Authorization:"bearer " + process.env.REACT_APP_API_TOKEN,
          }
        });
        setProducts(res.data.data);
        console.log(res);
      } catch (error) {
        console.log(error);
      }

    }
    fetchData();
    // console.log(products);

  },[]);

  const navId = localStorage.getItem('navId');
  useEffect(() => {
    if(navId === 'products' || navId === 'contactUs'){
      document.getElementById(navId).scrollIntoView({ behavior: 'smooth' });
      localStorage.clear();
    }
  });
  const questions = [
    {
      question: `How often should I use hoof oil on my horse's hooves?`,
      answer: `The answer depends on a few different factors, like the environment your horse is living in and how often they're being ridden. As a general rule, applying hoof oil once a week is a good starting point. But if you notice that your horse's hooves are feeling dry or brittle, you may want to apply it more often. The key is to pay attention to your horse's hooves and adjust your hoof oil application accordingly. `,
    },
    {
      question: `Can I use hoof oil on a horse with sensitive skin?`,
      answer: `Absolutely! Our hoof oil is made with natural ingredients and is gentle enough for horses with even the most sensitive skin. Of course, we always recommend that you do a patch test first just to be safe.`,
    },
    {
      question: `How does hoof oil benefit my horse's hooves?`,
      answer: `Using hoof oil on your horse's hooves helps moisturize and condition them, preventing cracking and splitting. It also forms a protective barrier against mud and moisture, which can weaken the hooves. Some hoof oils even contain natural ingredients like tea tree oil or peppermint, which can have a soothing effect.`,
    }
  ];
  
  const testimonials = [
    
    {
      id: '1',
      title: "Easy To Apply",
      text: "Not only is this a great value, but also it applies  smoothly and leaves my horse’s hooves looking shiny! - Jackie",
      imageUrl: TJackie,
    },
    {
      id: '2',
      title: "Amazing Product!",
      text: "Wonderful product for my horse. Knowing the ingredients are of good quality too is very reassuring also. - Greg",
      imageUrl: TEnoc,
    },
    {
      id: '3',
      title: "Game-Changer",
      text: "I used to struggle to find a hoof oil that worked well for my horse, but this product has exceeded my expectations. Thank you!",
      imageUrl: TDefault,
    },
  ];
  

  return (
    <main>
      <Hero />
      <Diffrenece />


      <Parallax strength={600} bgImage={Horse}>
        <section className='product-section' id='products'>
        {/* <Carousel className='product-carousel' itemsToShow={itemsToShow} > */}
        {products.map((product) => (
          <ProductCard
            product={product}
            key={product.product_id}
          />
        ))}

        {/* </Carousel> */}
        </section>
      </Parallax>

      {/* <div style={{minHeight:'100px'}}></div> */}

      <section className="testimonial-section">
        <h1 className='testimonial-title'>Testimonals</h1>
        <Carousel className='testimonial-carousel' itemsToShow={itemsToShow} key={itemsToShow}>
          {testimonials.map((testimonial) => (
            
            <TestimonialItem
              title={testimonial.title}
              text={testimonial.text}
              key={testimonial.id}
              imageUrl={testimonial.imageUrl}
              className='testimonial-item'
            />
        ))}
        </Carousel>
      </section>


      <section className="faq-section">
        <h1 className='faq-title'>FAQs</h1>
        <FAQ
          questions={questions}
        />
      </section>

      <section className="contact-section" id='contactUs'>
          <ContactUs />
      </section>
    </main>
  )
}

export default Home