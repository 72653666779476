import React, {useState} from 'react';
import '../ProductCard/ProductCard.scss';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/cartReducer';

const ProductCard = ({product}) => {
  const [isAdded, setIsAdded] = useState(false);

  const dispatch = useDispatch();
  const handleAddToCart = () => {
    dispatch(addToCart({
      id: product.id,
      sku:product.attributes.product_id,
      title:product.attributes.product_title,
      quantity:1,
      price:product.attributes.product_price,
      url: process.env.REACT_APP_UPLOAD_URL + product.attributes.product_photo.data.attributes.url,
    }));
    setIsAdded(true);
    setTimeout(() => {
      setIsAdded(false);
    }, 2000);
  };
  const handleBuyNow = () => {
    dispatch(addToCart({
      id: product.id,
      sku:product.attributes.product_id,
      title:product.attributes.product_title,
      quantity:1,
      price:product.attributes.product_price,
      url: process.env.REACT_APP_UPLOAD_URL + product.attributes.product_photo.data.attributes.url,

    }));
    window.location.href = '/cart';

  };
  return (
    <div className="product-card">
      <div className="product-image">
        <img src={process.env.REACT_APP_UPLOAD_URL + product.attributes.product_photo.data.attributes.url} alt="Product" />
      </div>
      <div className="product-details">
        <h2 className="product-title">{product.attributes.product_title}</h2>
        <p className="product-description">{product.attributes.product_description}</p>
        {/* <div className="product-options">
          <input type="radio" id="hdpe" name="product-option" value="hdpe" checked />
          <label htmlFor="hdpe">HDPE</label>
          <input type="radio" id="metal" name="product-option" value="metal" />
          <label htmlFor="metal">Metal</label>

        </div> */}
        <p className="product-price">${product.attributes.product_price}</p>
        <button className={`add-to-cart ${isAdded ? 'added' : ''}`} disabled={isAdded} onClick={handleAddToCart}>
          {isAdded ? 'Added' : 'Add to Cart'}
        </button>
        <button className="buy-now" onClick={handleBuyNow}>Buy Now</button>
      </div>
    </div>
  )
}

export default ProductCard
