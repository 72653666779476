import '../CartItem/CartItem.scss';
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { decrement,increment,removeItem } from '../../redux/cartReducer';

const CartItem = ({ id, initialValue, price, title, url  }) => {
  const [value, setValue] = useState(initialValue || 1);
  const dispatch = useDispatch();


  const handleChange = e => {
    const inputValue = e.target.value;
    // allow only numbers
    if (!isNaN(inputValue)) {
      setValue(inputValue);
    }
  };

  const handleIncrement = () => {
    dispatch(increment({id:id}));
    setValue(value + 1);
  };

  const handleDecrement = () => {
    dispatch(decrement({id:id}));
    setValue(value > 1 ? value - 1 : 1);
  };
    return (
      <div className='cart-item-container'>
        <Icon className='cart-item-trash' path={mdiTrashCanOutline} size={2} onClick={() => dispatch(removeItem({id:id}))}/>

        <img
          src={url}
          className="cart-item-thumbnail"
          alt='trash'
          
        />
        <span className="cart-details">
          <h1 className="cart-item-title">
            {title}
          </h1>
          <h2 className="cart-item-price">${price}</h2>
        </span>
        <span className="cart-item-qty-group">
        <button onClick={handleDecrement}>-</button>
          <input
            type="text"
            className="cart-item-qty-value"
            value={value}
            onChange={handleChange}
          />
          <button onClick={handleIncrement}>+</button>
        
        </span>
      </div>
    );
  };
  
  CartItem.propTypes = {
    initialValue: PropTypes.number
  };
export default CartItem;
  
