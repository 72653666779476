import React from 'react'
import '../Diffrenece/Diffrenece.scss'
const Diffrenece = () => {
    return (
        <section className='difference'>
            <div className="horseshoe">
                <img src="img/SVG/3.svg" alt="" srcSet="" />
            </div>
            <div className="dif-content">
                <h1 className="dif-title">The GallopGuard Difference</h1>
                <p className="dif-text">
                    At GallopGuard, we're passionate about providing the best natural hoof care
                    solutions for your horse. We take pride in our commitment to using only the
                    highest quality, natural ingredients that promote hoof health and prevent common problems.
                    Our carefully selected blend of natural oils and extracts works to moisturize and condition
                    the hoof, keeping it flexible and strong. But that's not all - our unique formula
                    also acts as a deterrent to pesky rodents that can wreak havoc on your horse's hooves.
                    At GallopGuard, we understand the importance of proper hoof care and are dedicated to
                    providing a safe and effective solution that you can trust. Choose GallopGuard for a natural
                    approach to hoof care that will leave your horse's hooves healthy and strong.
                </p>
            </div>
        </section>


    )
}

export default Diffrenece