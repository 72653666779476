import CartItem from "../../components/CartItem/CartItem";
import CartTotal from "../../components/CartTotal/CartTotal";
import "../Cart/Cart.scss";

import React from 'react';
import { useSelector } from 'react-redux';

const Cart = () => {

  const cartItems = useSelector(state => state.cart.products)


  return (
    <main>
      <img src="../img/cart-background.svg" alt="" srcSet="" className="cart-background" />
      <h1 className="cart-page-title">Cart</h1>
      <div className="cart-container">
        <div className="cart-items">
          {cartItems.length === 0 ?(
            <p className="empty-cart-message">No items in your cart</p>
          ):(cartItems.map((item) => (
            <CartItem
              id={item.id}
              initialValue={item.quantity}
              title={item.title}
              price={item.price}
              url={item.url}
            />
          )))}
        </div>
        <div className="cart-totals">
          <CartTotal />
        </div>
      </div>
    </main>
  )
}

export default Cart

