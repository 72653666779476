import React from 'react';
import '../PostCheckout/PostCheckout.scss';
import CheckoutResponse from '../../components/CheckoutResponse/CheckoutResponse';

const PostCheckout = () => {
  return (
    <main>
        <div className="post-checkout-wrapper">
            <CheckoutResponse />        
        </div>
    </main>
  )
}

export default PostCheckout