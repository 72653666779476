import React from 'react';
import './Footer.scss';

function Footer() {
  const handleClick = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  };
  
  return (
    <footer className="footer">
      <nav className="footer-nav">
        <ul>
          <li><a href="/#">Home</a></li>
          <li><a href="/#products" onClick={(event) => handleClick(event, 'products')}>Products</a></li>
          <li><a href="/#contactUs" onClick={(event) => handleClick(event, 'contactUs')}>Contact Us</a></li>
          <li><a href="/cart">Cart (<span style={{color:'red'}}>0</span>)</a></li>
          <li><a href="/return-policy">Return Policy</a></li>
          <li><a href="/privacy-policy">Privacy Policy</a></li>
          <li><a href="/tos">Terms of Service</a></li>

          
        </ul>
      </nav>
      <p className="copyright">
        &copy; 2023 All Rights Reserved GallopGuard Co.
      </p>
    </footer>
  );
}

export default Footer;
