import React, { useState } from 'react';
import styles from './Question.scss';
import classNames from 'classnames';

const Question = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='question' onClick={handleClick}>
      <div className='question__header'>
        <div className='question__header__text'>{question}</div>
        <div className={classNames('question__header__arrow', { [styles.open]: isOpen })}></div>
      </div>
      {isOpen && (
        <div className='question__answer'>
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

export default Question;
